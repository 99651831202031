<template>
  <fb-page :bgColor="{ background: '#eef7fc' }">
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button slot="left" icon="back" @click="back" size="small" type="primary"> 返回 </fb-button>
      <!-- <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button> -->
      <div class="use-ocr" slot="right" @click="openCaseCamera">
        <i class="iconfont icon-camera"></i>
      </div>
    </fb-header>
    <!-- 默认插槽 -->
    <div class="create-case-wrapper" ref="wrapper" :style="createCaseWrapperStyle">
      <div class="create-case-form" @click.self="hidePopup">
        <div class="basic-section">
          <div class="reason-wrapper">
            <reason-item :reasonSuggestion="reasonSuggestion" :reasonText="reasonText" ref="reasonItem" :flag="reasonSuggestionFlag" />
          </div>
          <div class="course JiGou" v-show="showCourseBlock">
            <!-- <div><span style="color:red">*</span>法院</div> -->
            <div>
              <i style="margin-right: 10px; color: #c8c9cc" class="iconfont icon-clear-2 mjechanism-clear" v-show="mechanism" @click="DeleteJiGou"></i>
            </div>
            <div class="course-input-wrapper">
              <input type="text" ref="inputRef" style="line-height: 20px" v-model="mechanism" placeholder="请填写审理机关" @focus="JiGouFocus" @blur="JiGouBlur" />
              <!-- <i class="van-icon van-icon-arrow-down"></i> -->
              <span class="judge-caseno" @click.stop="showJudgeBlock = !showJudgeBlock">审理人员案号</span>
              <div v-if="showJiGouLabel" class="jigou-label" @click="showJiGouLabel = false">
                <span>{{ mechanism }}</span>
                <!-- <i class="iconfont icon-clear-2 label-clear" @click="removeLabel"></i> -->
              </div>
              <ul class="liebiao" v-show="showCoursePanel" @click="showCoursePanel = false">
                <li v-for="(item, index) in mechLie" :key="index" @click.stop="SelectJiGou(item.label, item.cid, index)">
                  <span>{{ item.label }}</span>
                  <span v-show="item.isSure" @click.stop="sureAdd(item.label, item.cid)">确定</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="judge JiGou" v-show="showCourseBlock && showJudgeBlock">
            <div><span>办案人</span></div>
            <div class="judge-input-wrapper">
              <p v-for="(item, index) in FBlist" :key="index" class="DiZao">
                <span>{{ item }}</span>
                <i class="iconfont icon-clear" @click="deleteJudge(index)"></i>
              </p>
              <input type="text" v-model="faguan" @focus="FB" style="line-height: 20px" placeholder="请填写或选择" @keyup="hanldeKeySpace" @blur="ShiJiao" />
              <!-- <i class="van-icon van-icon-arrow-down"></i> -->
              <ul class="liebiao" v-show="showJudgePanel">
                <li v-for="(item, index) in FGlist" :key="index" @click="FGLIst(item)">
                  {{ item }}
                </li>
                <li v-if="emptyFG" class="no-data">无推荐数据</li>
              </ul>
            </div>
          </div>
          <div class="caseNo" v-show="showCourseBlock && showJudgeBlock">
            <van-field label="案号" v-model="caseNo" placeholder="请输入并选择案号" input-align="right" clearable />
          </div>
          <!-- 先选择法院再展示审级 -->
          <van-collapse v-model="activeName" v-show="(showCourseBlock && selectCourse && mechanism) || (shenji && chengxu && reason)" class="shenlijibie" accordion>
            <van-collapse-item title="审级" name="shenjicollapse" :value="shenlijibie">
              <van-radio-group v-model="shenji" @change="shenjiChange" class="shenji-radio-group">
                <van-radio :name="s.attrValueId" v-for="s in shenjiCell" :key="s.id">{{ s.name }}</van-radio>
              </van-radio-group>
              <van-radio-group v-show="shenji && showChenxu" v-model="chengxu" @change="chengxuChange" class="chengxu-radio-group">
                <van-radio :name="s.attrValueId" v-for="s in chengxuCell" :key="s.id">{{ s.name }}</van-radio>
              </van-radio-group>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="time-section" v-if="(showCourseBlock && chengxu) || (!showCourseBlock && reason)">
          <!-- 收案时间 -->
          <div style="display: flex; justify-content: space-between">
            <van-field
              class="accept-time"
              readonly
              :clickable="true"
              :clearable="true"
              name="datetimePicker"
              :value="acceptTime"
              label="收案时间"
              placeholder="点击选择时间"
              @click="showTimePicker = true"
            >
            </van-field>
            <div class="shou-fei" @click.stop="showSF = !showSF">收费</div>
          </div>
          <div v-show="showSF">
            <!-- 收费方式 -->
            <van-field readonly :clickable="true" :clearable="true" name="picker" :value="chargingMethodLabel" label="收费方式" placeholder="点击选择收费方式" @click="showMethodPicker = true" />
            <van-popup v-model="showMethodPicker" position="bottom">
              <van-picker show-toolbar :columns="chargingEnumOptions" @confirm="onMethodConfirm" @cancel="showMethodPicker = false" />
            </van-popup>
            <!-- 律师费 减免 -->
            <van-field v-model="agencyFee" label="律师费" name="agencyFee" type="number" :clickable="true" :clearable="true" input-align="right" placeholder="请输入律师费">
              <!-- <template #label>
              <div class="agency-label" style="width:300px">
                <span style="width:100px;margin-right:10px">律师费</span>
                <span>{{ formatagencyFee }}</span>
              </div>
            </template> -->
              <template #button>
                <span>元</span>
              </template>
            </van-field>
            <!-- <van-field
            v-model="agencyFeeReduction"
            name="agencyFeeReduction"
            type="number"
            label="减免费用(元)"
            placeholder="请输入减免费用"
          /> -->
          </div>
        </div>
        <div class="person-area" v-show="showPersonBlock && !isEmptyCharging">
          <div class="yuangao-section">
            <div class="sec">
              <div class="title">{{ firstPersonName }}</div>
              <div class="action" @click="addPerson(firstPersonType)"><i class="iconfont icon-tianjia1"></i>{{ '添加' + firstPersonName }}</div>
            </div>
            <div class="person-list">
              <person-item
                v-for="(p, i) in filterAllPerson(firstPersonType)"
                :key="p.refname"
                :personData="p"
                :index="i"
                :type="firstPersonGroup"
                @resetDefaultWtr="resetWtr"
                @deletePerson="handleDeletePerson"
                :ref="p.refname"
              />
            </div>
          </div>
          <div class="beigao-section" v-if="personGroup.length >= 2">
            <div class="sec">
              <div class="title">{{ secondPersonName }}</div>
              <div class="action" @click="addPerson(secondPersonType)"><i class="iconfont icon-tianjia1"></i>{{ '添加' + secondPersonName }}</div>
            </div>
            <div class="person-list">
              <person-item
                v-for="(p, i) in filterAllPerson(secondPersonType)"
                :key="p.refname"
                :personData="p"
                :index="i"
                :type="secondPersonGroup"
                @resetDefaultWtr="resetWtr"
                @deletePerson="handleDeletePerson"
                :ref="p.refname"
              />
            </div>
          </div>
          <div class="other-sections" v-if="otherGroup.length">
            <div class="person-section" v-for="g in otherGroup" v-show="filterAllPerson(g.enum).length" :key="g.attrValueId">
              <div class="sec">
                <div class="title">{{ g.name }}</div>
                <div class="action" @click="addPerson(g.enum)"><i class="iconfont icon-tianjia1"></i> {{ '添加' + g.name }}</div>
              </div>
              <div class="person-list">
                <person-item
                  v-for="(p, i) in filterAllPerson(g.enum)"
                  :key="p.refname"
                  :personData="p"
                  :index="i"
                  :type="g"
                  @resetDefaultWtr="resetWtr"
                  @deletePerson="handleDeletePerson"
                  :ref="p.refname"
                />
              </div>
            </div>
          </div>
          <div class="add-other-type-person" v-if="otherGroup.length && chengxu">
            <span @click="showOtherGroupPicker = true"> <i class="iconfont icon-tianjia1"></i> 添加其他当事人 </span>
            <van-action-sheet v-model="showOtherGroupPicker">
              <van-picker
                :columns="otherGroupPickerData"
                :item-height="pickCellheight"
                show-toolbar
                title="选择当事人类型"
                :visible-item-count="5"
                @cancel="showOtherGroupPicker = false"
                @confirm="onOtherGroupPickerConfirm"
              />
            </van-action-sheet>
          </div>
        </div>
        <approval-user
          v-if="this.organizationId && this.hasApprovalUser && this.isBackCaseManager && !this.isEditCaseMode && !this.createCaseBtnStyle.background"
          :Approval="this.Approval"
          :CC="this.CC"
          :isAdd="0"
          :isShowCaseMode="true"
          :caseMode="caseMode"
          :approvalUserSource="approvalUser"
          :ccUserSource="ccUser"
          @userSelect="userSelect"
          @coopSelect="coopSelect"
          @changeCaseMode="changeCaseMode"
        />
        <div class="create-case-btn">
          <!-- 清除   重新检索 -->
          <div class="btn" :style="createCaseBtnStyle" @click="createCase('YES')" v-if="!isBackCaseManager">保存案件</div>
          <div v-if="isBackCaseManager && this.organizationId" class="btn" style="margin-left: 10px" :style="createCaseBtnStyle" @click="createCase('YES', 'UNAUDITED')">申请立案</div>
        </div>
      </div>
      <van-dialog v-model="showConflict" show-cancel-button title="疑似存在冲突案件" message="系统检测发现，疑似存在冲突案件，是否继续创建？" @confirm="confirmCreateConflictCase" />
    </div>
    <van-popup v-model="showTimePicker" position="bottom" get-container="body">
      <van-datetime-picker v-model="acceptCaseDate" type="date" :min-date="minDate" :max-date="maxDate" @confirm="onTimeConfirm" @cancel="showTimePicker = false" />
    </van-popup>
  </fb-page>
</template>

<script>
import reasonData from '@/common/reasonData'
import ApprovalUser from '../OA/components/approvalUser'
import { caseAddRadioData } from './const/caseAddCellData'
import { paramCode } from './const'
import { genID } from '@/common/utils'
import goBackMixin from '@mixins/goBack'

import { caseBase, pydataBase } from '~api-config'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import ReasonItem from '@components/reasonPicker'
import PersonItem from './components/personItem'

import Qs from 'qs'

const subject = [
  {
    enum: 'PLAINTIFF',
    name: '原告',
    attrKeyId: 10006,
    attrValueId: 10017,
  },
  {
    enum: 'DEFENDANT',
    name: '被告',
    attrKeyId: 10006,
    attrValueId: 10018,
  },
  {
    enum: 'THIRD_PARTY',
    name: '第三人',
    attrKeyId: 10006,
    attrValueId: 10019,
  },
  {
    enum: 'NOT_INVOLVED_CASE',
    name: '案外人',
    attrKeyId: 10006,
    attrValueId: 13249,
  },
  {
    enum: 'APPLICANT',
    name: '申请人',
    attrKeyId: 10006,
    attrValueId: 13258,
  },
  {
    enum: 'APPELLANT',
    name: '上诉人',
    attrKeyId: 10006,
    attrValueId: 13250,
  },
  {
    enum: 'CLAIMANT',
    name: '被申请人',
    attrKeyId: 10006,
    attrValueId: 13259,
  },
]

const cat = {
  0: 'NATURAL_PERSON',
  1: 'LEGAL_ENTITY',
  2: 'NON_LEGAL_ENTITY',
}

// const flag = false
export default {
  name: 'create-case-page',
  components: {
    ReasonItem,
    PersonItem,
    ApprovalUser,
  },
  mixins: [goBackMixin],
  data() {
    return {
      maxDate: new Date(2100, 12, 31),
      minDate: new Date(1900, 1, 1),
      // cameraFalg: false,
      title: '新建项目',
      // BS滚动
      scroll: null,
      // 顶级案由
      firstcaseReason: null,
      // 显示其他类型当事人选择器
      showOtherGroupPicker: false,
      activeName: 'shenjicollapse',
      mechanism: '', // 法院
      showJiGouLabel: false, // 法院标签
      selectCourse: false, // 点击选择了法院
      courtId: null, // 法院id
      BR: false, // 法院输入框失焦
      mechLie: [], // 法院列别数据
      showCoursePanel: false,
      showJudgeBlock: false, // 是否显示法官案号模块
      showJudgePanel: false,
      emptyFG: false, // 法官列表为空
      caseNo: '', // 案号
      faguan: '', // 新增的法官
      FBlist: [], // 法官
      FGlist: [], // 法官列表数据
      // showShenji: true, // 是否显示审级用于清空案由时控制是否展示审理级别的
      // 审级选项
      shenjiCell: [],
      // 程序选项
      chengxuCell: [],
      // 选择的审级attrValueId
      shenji: '',
      showChenxu: false,
      // 选择的程序attrValueId
      chengxu: '',
      // 收案时间
      acceptTime: '', // 展示
      acceptCaseDate: new Date(), // date
      acceptCaseTime: null, // 时间戳
      showSF: false, // 是否展示收费区域
      // 收费方式 枚举ChargingMethodEnum
      chargingEnum: [
        {
          value: 'FREE',
          label: '免费',
        },
        {
          value: 'PIECEWORK_CHARGE',
          label: '记件收费',
        },
        {
          value: 'PROPORTIONALLY_CHARGED',
          label: '按标的额固定比例收费',
        },
        {
          value: 'RISK_AGENCY',
          label: '风险代理',
        },
        {
          value: 'TIMING_CHARGE_SERVICE',
          label: '计时收费',
        },
        {
          value: 'OBJECT_AND_FIXED_AGENT',
          label: '标的+固定代理',
        },
        {
          value: 'OTHER',
          label: '其他',
        },
      ],
      chargingEnumOptions: ['免费', '记件收费', '按标的额固定比例收费', '风险代理', '计时收费', '标的+固定代理', '其他'],
      chargingMethod: null,
      chargingMethodLabel: '',
      showMethodPicker: false,
      // 律师费
      agencyFee: null,
      // 减免费
      // agencyFeeReduction: null,
      showTimePicker: false,
      // 所有类型当事人 注意Vue无法检测到未定义的对象属性的变动 这里数据不是很多可以先把所有类型都放在这里
      // TODO 使用Object.assign以满足动态扩充
      allPerson: {
        PLAINTIFF: [],
        DEFENDANT: [],
        THIRD_PARTY: [],
        APPELLANT: [],
        APPELLEE: [],
        NOT_INVOLVED_CASE: [],
        NO_TYPE: [],
        CRIMINAL_SUSPECT: [],
        FIRST_TRIAL_PLAINTIFF: [],
        FIRST_TRIAL_DEFENDANT: [],
        FIRST_TRIAL_THIRD_PARTY: [],
        GRIEVANT: [],
        RESPONDENT: [],
        APPLICANT: [],
        CLAIMANT: [],
        EXECUTION_APPLICANT: [],
        EXECUTEE: [],
        DEFENDANT_PEOPLE: [],
        DRIVER: [],
        CAR_OWNER: [],
        VICTIM: [],
        INSURANCE_COMPANY: [],
        PASSENGER: [],
      },
      // 解析后的数据
      parsedData: {},
      // 诉讼请求
      claimText: null,
      // 实施理由
      briefCaseText: null,
      // 候选案由文字数据
      reasonText: '',
      // 候选案由列表数据
      reasonSuggestion: [],
      // 案由标记用于检测候选案由发生变化
      reasonSuggestionFlag: 1,
      // 是否默认添加第一个当事人
      shouldAddFirstPerson: true,
      // 冲突案件提示
      showConflict: false,
      // 是否应该重置程序值
      shouldClearChengxu: true,
      // 是否编辑案件
      isEditCaseMode: false,
      // 是否应该设置当前当事人为默认委托人
      shouldSetAsWtr: true,
      hasDocForCreation: false,
      // 直接返回到原生
      directGoNative: false,
      fileId: null,
      // 是否返回OA案件管理
      isBackCaseManager: false,
      CC: [], // 抄送人列表
      Approval: [], // 审批人列表
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      coopUser: null,
      caseTypeName: '其他非诉',
      hasApprovalUser: false,
      caseMode: 0,
      // 是否从oa界面进入
      // fromOaPath: true
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
    ...mapState('createCase', ['reason', 'shouldActiveWtrType', 'wtrType', 'doc']),
    ...mapState('user', ['organizationId', 'userInfo']),
    // 费用区域是否为空 收案时间 收费方式 律师费全为空时不显示原被告
    isEmptyCharging() {
      if (!this.acceptCaseTime && !this.chargingMethod && !this.agencyFee) {
        return true
      } else {
        return false
      }
    },
    // 格式化展示律师费
    formatagencyFee() {
      if (this.agencyFee || this.agencyFee === 0) {
        if (this.agencyFee < 1000) {
          return this.agencyFee + '元'
        } else if (this.agencyFee < 10000 && this.agencyFee >= 1000) {
          const yuan = Math.floor(this.agencyFee % 1000) === 0 ? '元' : Math.floor(this.agencyFee % 1000) + '元'
          return Math.floor(this.agencyFee / 1000) + '千' + yuan
        } else {
          const wan = Math.floor(this.agencyFee / 10000) + '万'
          const qian = Math.floor((this.agencyFee / 1000) % 10) === 0 ? '' : Math.floor((this.agencyFee / 1000) % 10) + '千'
          const yuan = Math.floor(this.agencyFee % 1000) === 0 ? '元' : Math.floor(this.agencyFee % 1000) + '元'
          return wan + qian + yuan
        }
      } else {
        return ''
      }
    },
    // 是否显示法院
    showCourseBlock() {
      if (this.reason) {
        if (this.caseTypeName === '民事' || this.caseTypeName === '刑事' || this.caseTypeName === '行政' || this.caseTypeName === '执行') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    // 审理级别文字提示
    shenlijibie() {
      const shenji = paramCode.getTrialGradeName(this.shenji)
      const chengxu = paramCode.getProcedureName(this.chengxu) || paramCode.firstCaseReason(this.chengxu) || paramCode.getLitigantIdentity(this.chengxu)
      if (shenji && !chengxu) {
        return shenji
      } else if (shenji && chengxu) {
        if (this.shenji === this.chengxu) {
          return `${shenji}`
        } else {
          return `${shenji} ${chengxu}`
        }
      } else {
        return '请选择审理级别'
      }
    },
    showPersonBlock() {
      if (this.reason && (this.chengxu || this.personGroup.length === 1)) {
        return true
      } else {
        return false
      }
    },
    // 当事人分组
    personGroup() {
      if (this.chengxuCell.length && this.chengxu !== '') {
        const chengxu = this.chengxuCell.find((item) => {
          return item.attrValueId === this.chengxu
        })
        console.log(this.chengxu, this.chengxuCell)
        if (chengxu) {
          return chengxu.sub
        } else {
          return []
        }
      } else if (this.firstcaseReason && this.firstcaseReason.litigantType && this.firstcaseReason.litigantType.enum === 'NO_TYPE') {
        return [this.firstcaseReason.litigantType]
      } else {
        return []
      }
    },
    // 其他当事人分组
    otherGroup() {
      if (this.personGroup.length >= 2) {
        const attrValueId1 = this.firstPersonGroup.attrValueId
        const attrValueId2 = this.secondPersonGroup.attrValueId
        return this.personGroup.filter((item) => {
          return item.attrValueId !== attrValueId1 && item.attrValueId !== attrValueId2
        })
      } else {
        return []
      }
    },
    otherGroupPickerData() {
      const o = this.otherGroup
      const d = []
      o.forEach((item) => {
        const a = item
        a.text = item.name
        d.push(a)
      })
      return d
    },
    firstPersonGroup() {
      if (this.personGroup.length) {
        return this.personGroup[0]
      } else {
        return {}
      }
    },
    firstPersonName() {
      if (this.firstPersonGroup && this.firstPersonGroup.name) {
        return this.firstPersonGroup.name
      } else {
        return ''
      }
    },
    firstPersonType() {
      if (this.firstPersonGroup && this.firstPersonGroup.enum) {
        return this.firstPersonGroup.enum
      } else {
        return ''
      }
    },
    secondPersonGroup() {
      if (this.personGroup.length) {
        return this.personGroup[1]
      } else {
        return {}
      }
    },
    secondPersonName() {
      if (this.secondPersonGroup && this.secondPersonGroup.name) {
        return this.secondPersonGroup.name
      } else {
        return ''
      }
    },
    secondPersonType() {
      if (this.secondPersonGroup && this.secondPersonGroup.enum) {
        return this.secondPersonGroup.enum
      } else {
        return ''
      }
    },
    pickCellheight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    createCaseWrapperStyle() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      const h = document.documentElement.clientHeight
      const height = h - 44 * dpr
      const style = {
        height: `${height}px`,
      }
      return style
    },
    createCaseBtnStyle() {
      const hasReason = this.reason && this.reason.id
      if (hasReason && this.acceptCaseTime) {
        if (this.showCourseBlock && this.shenji && this.chengxu) {
          return {}
        } else if (!this.showCourseBlock) {
          return {}
        } else {
          return {
            background: '#a3a3a3',
          }
        }
      } else {
        return {
          background: '#a3a3a3',
        }
      }
    },
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('createCase', ['setReason', 'setShouldActiveWtrType', 'setWtrType']),
    // 案由删除初始化数据
    initData() {
      console.log('清空案由', this.reason)
      this.mechanism = ''
      this.caseNo = ''
      this.FBlist = []
      this.FGlist = []
      this.shenji = ''
      this.chengxu = ''
      this.selectCourse = false
      this.acceptTime = '' // 展示
      this.acceptCaseDate = new Date() // date
      this.acceptCaseTime = null // 时间戳
      this.chargingMethod = null
      this.chargingMethodLabel = ''
      this.agencyFee = null
      this.shouldSetAsWtr = true
      this.shouldAddFirstPerson = true
      this.setWtrType(null)
      this.refreshPersonDataAndInit()
    },
    changeCaseMode(caseMode) {
      this.caseMode = caseMode
    },
    // 顶级案由发生变化
    resetInitData() {
      console.log('顶级案由发生变化', this.firstcaseReason)
      this.selectCourse = true
      if (this.firstcaseReason) {
        this.shenjiCell = this.firstcaseReason.sub
      } else {
        this.shenjiCell = []
      }
      console.log('this.chengxuCell====>', this.chengxuCell)
      if (this.$route.query.reasonText || (this.$route.query.caseId && this.$route.query.useDoc === 'YES')) {
        this.shenji = 10014
        this.chengxu = 10020
        this.shouldClearChengxu = false
        setTimeout(() => {
          this.shouldClearChengxu = true
        }, 100)
        this.onTimeConfirm(new Date())
      } else {
        this.shenji = ''
        this.chengxu = ''
      }
      // this.shenji = ''
      // this.chengxu = ''

      // 法官 当事人要清空重新获取
      this.FBlist = []
      this.FGlist = []
      // this.refreshPersonDataAndInit()
      if (this.firstcaseReason && this.firstcaseReason.name) {
        this.caseTypeName = this.firstcaseReason.name
      }
    },
    // 编辑模式下切换顶级案由
    dealEditReset() {
      if (this.firstcaseReason && this.firstcaseReason.name) {
        this.caseTypeName = this.firstcaseReason.name
      }
      if (this.firstcaseReason) {
        this.shenjiCell = this.firstcaseReason.sub
      } else {
        this.shenjiCell = []
      }
      this.shenji = ''
      this.chengxu = ''
      this.selectCourse = true
      this.FBlist = []
      this.FGlist = []
    },
    // 选择收案时间
    onTimeConfirm(time) {
      console.log(time)
      this.acceptTime = time.getFullYear() + '年' + (time.getMonth() + 1) + '月' + time.getDate() + '日'
      this.acceptCaseTime = new Date(time).getTime()
      this.showTimePicker = false
    },
    // 选择收费方式
    onMethodConfirm(method) {
      this.chargingEnum.forEach((item) => {
        if (item.label === method) {
          this.chargingMethodLabel = item.label
          this.chargingMethod = item.value
        }
      })
      this.showMethodPicker = false
    },
    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      if (this.organizationId) {
        this.getUsers()
      }
    },
    // 获取审批人和抄送人
    async getUsers() {
      this.hasApprovalUser = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: this.caseTypeName,
        type: 'LEGAL_CASE_ADD',
      }
      const res = await this.$axios.post(`${this.$base}/management/org/advice/user`, params)
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        this.hasApprovalUser = true
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach((item) => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName,
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName,
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        this.ccUser = arrlist[0]
        this.approvalUser = arr[0]
      } else {
        this.hasApprovalUser = false
      }
    },

    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },
    handleSelectItem(options) {
      console.log(options)
    },
    back() {
      if (this.directGoNative || window.nativeEnterIndex >= this.$router.length) {
        this.backToNative()
      } else {
        if (this.$route.query.fromAna) {
          this.$router.back()
        } else {
          this.goBack()
        }
      }
    },
    // 审级发生改变时
    shenjiChange(name) {
      // debugger
      // 程序发生改变
      if ((name !== this.chengxu) & !this.hasDocForCreation & !this.isEditCaseMode) {
        this.shouldAddFirstPerson = true
      }
      if (this.shouldClearChengxu) {
        this.chengxu = 0
        // 清空当事人数据
        // if (this.shouldAddFirstPerson) {
        //   this.clearPerson()
        // }
        // 重新设置 激活默认当事人为代理人
        this.setShouldActiveWtrType(true)
        this.shouldSetAsWtr = true
      }
      // 筛选审级选项数据
      if (this.shenjiCell.length && name) {
        this.chengxuCell = this.shenjiCell.find((item) => {
          return item.attrValueId === name
        }).sub
      }
      if (this.caseTypeName === '刑事') {
        this.chengxu = this.shenji
        this.showChenxu = false
        if (this.shouldAddFirstPerson) {
          this.clearPerson()
          this.addPerson(this.firstPersonType)
        }
      } else {
        this.showChenxu = true
      }
    },
    // 选择审级
    chengxuChange(name) {
      // 如果程序有选中值 则关闭审级折叠面板
      if (this.chengxu !== 0) {
        this.activeName = ''
        if (this.shouldAddFirstPerson) {
          this.addPerson(this.firstPersonType)
        }
      }
    },
    clearPerson() {
      const keys = Object.keys(this.allPerson)
      keys.forEach((item) => {
        this.allPerson[item] = []
      })
    },
    // 添加当事人
    addPerson(type = 'other') {
      console.log('addPerson 添加当事人', type)
      const ref = genID()
      const p = {
        refname: ref,
        category: '',
        displayClient: '',
        // 法人或非法人单位名称 或自然人姓名
        name: '',
        sex: '',
        nation: '',
        idNumber: '',
        phone: '',
        address: '',
        selfColumnName: '',
        selfColumnValue: '',
        memo: '',
        profession: '',
        workOffice: '',
        // 管理者姓名
        manager: '',
        // 职务
        position: '',
        longResidence: '',
        postalCode: '',
        documentDelivery: '',
      }
      if (this.shouldSetAsWtr) {
        p.displayClient = 'YES'
      }
      if (!this.allPerson[type]) {
        this.allPerson[type] = []
      }
      // console.log(this.allPerson, this.allPerson[type], 'allPerson')
      const g = this.allPerson[type]
      const ng = [...g, p]
      const allP = this.allPerson
      allP[type] = ng
      this.allPerson = allP
      this.shouldAddFirstPerson = false
      this.shouldSetAsWtr = false
    },
    // 删除当事人
    handleDeletePerson(payload) {
      const y = this.allPerson[payload.type]
      const nY = y.splice(payload.index, 1)
      console.log('删除当事人: ', nY)
      // this.allPerson[payload.type] = y
    },
    // 获取所有新建项目数据
    getData() {
      // 当事人数据
      const litigantList = []
      const keys = Object.keys(this.allPerson)
      let undefinedPersonNum = 0
      let hasErr = false
      try {
        keys.forEach((key) => {
          const personList = this.allPerson[key]
          this.$nextTick(() => {
            if (personList.length) {
              personList.forEach((item) => {
                const personData = this.$refs[item.refname][0].getData()
                if (personData === null) {
                  hasErr = true
                } else if (personData) {
                  litigantList.push(personData)
                } else {
                  undefinedPersonNum += 1
                }
              })
            }
          })
        })
      } catch (err) {
        console.log('getData错误：', err)
      }

      if (undefinedPersonNum) {
        this.$toast(`有${undefinedPersonNum}个当事人信息为空 将会忽略`)
      }
      if (!hasErr) {
        // this.firstcaseReason = caseAddRadioData.caseTypesV2.find(item => {
        //   return item.name === this.caseTypeName
        // })
        const caseDataToAdd = {
          caseType: this.reason.caseReasonType,
          firstcaseReasonKeyId: this.firstcaseReason.attrKeyId,
          firstcaseReasonValueId: this.firstcaseReason.attrValueId,
          causeName: this.reason.text,
          caseReasonId: this.reason.id,
          briefCaseText: this.briefCaseText,
          claimText: this.claimText,
        }
        let shenjiObj = null
        let chengxuObj = null
        if (this.showCourseBlock) {
          shenjiObj = this.shenjiCell.find((item) => {
            return item.attrValueId === this.shenji
          })
          if (this.caseTypeName !== '刑事') {
            chengxuObj = this.chengxuCell.find((item) => {
              return item.attrValueId === this.chengxu
            })
          }
          if (shenjiObj) {
            caseDataToAdd.trialGradeKeyId = shenjiObj.attrKeyId
            caseDataToAdd.trialGradeValueId = shenjiObj.attrValueId
          }
          if (chengxuObj) {
            caseDataToAdd.procedureTypeKeyId = chengxuObj.attrKeyId
            caseDataToAdd.procedureTypeValueId = chengxuObj.attrValueId
          }
        }
        caseDataToAdd.litigantList = litigantList
        return caseDataToAdd
      } else {
        return false
      }
    },
    // 通知原生端刷新案件列表
    notifyRefreshCase() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'
      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          // eslint-disable-next-line
          AND2JS.refreshCase()
        } catch {
          console.log('原生端环境才可以刷新案件列表')
        }
      } else if (ua === iOSUA) {
        try {
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.refreshCase({})
        } catch {
          console.log('原生端环境才可以刷新案件列表')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.refreshCase('{}')
        } catch (error) {
          console.log('back.refreshCase', error)
        }
      }
    },
    // 新建项目
    async createCase(conflict = 'YES', progress) {
      const valid = this.checkData()
      if (valid) {
        const data = await this.getData()
        if (data) {
          const message = this.isEditCaseMode ? '正在保存案件' : '正在新建项目'
          const loading = this.$toast.loading({
            mask: true,
            duration: 0,
            message: message,
            forbidClick: true,
          })
          let agencyInfs = null
          if (this.showCourseBlock) {
            if (!this.courtId) {
              this.$toast('请选择法院')
              return
            } else {
              if (this.FBlist) {
                if (this.FBlist.length) {
                  this.judge = this.FBlist.join(',')
                } else {
                  this.judge = ''
                }
              } else {
                this.judge = null
              }
              agencyInfs = {
                caseId: this.caseId ? this.caseId : null,
                courtId: this.courtId ? this.courtId : 0,
                courtName: this.mechanism ? this.mechanism : null,
                judge: this.judge ? '[' + this.judge + ']' : null,
                caseNo: this.caseNo ? this.caseNo : null,
              }
            }
          }
          if (!this.acceptCaseTime) {
            this.$toast('请选择收案时间')
            return
          }
          data.agencyInfs = agencyInfs
          data.acceptCaseTime = this.acceptCaseTime
          if (this.chargingMethod) {
            data.chargingMethod = this.chargingMethod
          }
          if (this.agencyFee) {
            data.agencyFee = this.agencyFee
          }
          // 编辑案件
          if (this.isEditCaseMode) {
            const caseId = this.$route.query.caseId
            data.caseId = caseId
            data.agencyInfs.caseId = caseId
            data.caseMode = this.caseMode
            this.$axios
              .put(`${caseBase}/lts/case/updateCase`, data)
              .then((res) => {
                loading.clear()
                const message = res.data.message
                if (res.data.code === '200') {
                  // this.$toast('修改成功 即将跳转到案件详情页')
                  this.$toast(message)
                  setTimeout(() => {
                    this.$router.push({
                      name: 'casedetail',
                      params: { caseId },
                    })
                  }, 1500)
                } else {
                  this.$toast(message)
                }
              })
              .catch((err) => {
                loading.clear()
                Promise.reject(err)
              })
          } else {
            data.conflict = conflict
            data.fileId = this.fileId
            console.log('新建项目的参数===>', data)
            if (progress) {
              data.progress = progress
            }
            if (this.organizationId && this.hasApprovalUser) {
              const userObj = []
              userObj[0] = {
                type: 'APPLICANT',
                user: {
                  id: this.userInfo.id,
                },
              }
              userObj[1] = {
                type: 'APPROVER',
                user: {
                  id: this.approvalUser.userid,
                },
              }
              userObj[2] = {
                type: 'RECIPIENT',
                user: {
                  id: this.ccUser.userid,
                },
              }
              if (this.coopUser) {
                userObj.push({
                  type: 'APPROVER',
                  user: {
                    id: this.coopUser.id,
                  },
                })
              }
              data.approveRecordUsers = userObj
              data.caseMode = this.caseMode
            }
            // data.acceptCaseTime = new Date().getTime()
            this.$axios({
              method: 'post',
              url: `${caseBase}/lts/case/createCase`,
              data,
            })
              .then((res) => {
                loading.clear()
                const { code, message } = res.data
                if (code === '2020') {
                  // 检测到重复案件 选择是否继续创建
                  this.showConflict = true
                } else if (code === '410') {
                  this.$notify(message)
                } else if (code === '200') {
                  const caseId = res.data.data
                  if (caseId) {
                    if (this.claimText && this.briefCaseText) {
                      this.saveClaim(caseId)
                    } else {
                      this.$toast(message)
                      // 是否返回OA的案件管理
                      if (this.isBackCaseManager) {
                        // this.$toast('新建项目成功')
                        this.$router.go(-1)
                      } else {
                        // this.$toast('新建项目成功 即将跳转到本案件')
                        this.notifyRefreshCase()
                        // setTimeout(() => {
                        this.$router.push({
                          name: 'casedetail',
                          params: { caseId },
                        })
                        // }, 1000)
                      }
                    }
                  }
                }
              })
              .catch((err) => {
                loading.clear()
                Promise.reject(err)
                this.$toast('新建项目失败')
              })
          }
        } else {
          console.log('数据存在问题')
        }
      }
    },
    // 确认创建重复案件
    confirmCreateConflictCase() {
      this.createCase('NO')
    },
    // 保存事实理由和诉讼请求
    saveClaim(caseId) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: '正在保存诉讼请求和实施理由',
        forbidClick: true,
      })
      const userId = this.$store.state.user.userInfo.id
      const data = {
        caseId,
        userId,
        claimText: this.claimText,
        briefCaseText: this.briefCaseText,
      }
      this.$http
        .put(`${caseBase}/new-design/caseUserDocument/create`, Qs.stringify(data))
        .then((res) => {
          loading.clear()
          this.$toast('新建项目成功 即将跳转到本案件')
          this.notifyRefreshCase()
          setTimeout(() => {
            this.$router.push({ name: 'casedetail', params: { caseId } })
          }, 1000)
        })
        .catch((err) => {
          loading.clear()
          Promise.reject(err)
        })
    },
    // 检查数据是否有效
    checkData(data) {
      // 检查案由
      let validReason = false
      if (this.reason && this.reason.text && this.reason.id) {
        validReason = true
      } else {
        this.$notify('请填写案由')
        return false
      }
      // 检查审级
      let validShenji = false
      if (this.showCourseBlock) {
        if (this.shenji) {
          validShenji = true
        } else {
          this.$notify('请选择审级')
          return false
        }
      } else {
        validShenji = true
      }

      // 检查程序
      let validChengxu = false
      if (this.showCourseBlock) {
        if (this.chengxu) {
          validChengxu = true
        } else {
          this.$notify('请选择程序')
          return false
        }
      } else {
        validChengxu = true
      }

      // 检查委托人是否设置
      let validWtr = false
      if (this.wtrType && this.wtrType) {
        validWtr = true
      } else {
        this.$notify('请设置委托人')
        return false
      }

      return validReason && validShenji && validChengxu && validWtr
    },
    // 返回分组当事人数据
    filterAllPerson(t) {
      // console.log('tttt=========>', t, this.allPerson)
      if (this.allPerson[t]) {
        return this.allPerson[t]
      } else {
        return []
      }
    },
    // 其他类型当事人 - 确认选择
    onOtherGroupPickerConfirm(value, index) {
      this.addPerson(value.enum)
      this.showOtherGroupPicker = false
    },
    // 打开相机
    openCaseCamera() {
      console.log('showCaseCameraV2+++---77')
      try {
        // this.cameraFalg = true
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showCaseCameraV2()
        } else if (ua === 'fb-iOS-Webview') {
          window.webkit.messageHandlers.showCaseCameraV2.postMessage({})
        } else if (ua === 'fb-flutter-Webview') {
          window.showCaseCameraV2.postMessage('{}')
        } else {
          this.$notify('非原生环境')
        }
      } catch (err) {
        Promise.reject(err)
        this.$notify('非APP环境无法使用相机功能')
        console.log('showCaseCameraV2.postMessage', err)
      }
    },
    // ocr回调。
    ocrCaseResultCallback(data) {
      console.log('ocr回调======>', data)
      if (data && data !== '') {
        this.parseHTML(data.split('#!&&&!#')[0])
        // ocrFiles包含了多个id，以英文的逗号隔开。
        const tempList = data.split('#!&&&!#')[1].split(',')
        this.fileId = tempList.map((item) => {
          return parseInt(item)
        })
      }
      // else {
      //   this.$notify('当前内容为空 无法进行解析')
      // }
      // console.log('ocr回调结束')
    },
    // 解析HTML
    //  * @param html 必参 文书html内容
    //  * @param documentId 必参 文书documentId
    //  * @param documentModel 必参 默认传0 为4表示上传的文书
    parseHTML(txt) {
      // this.isParseHTML = true
      const data = {
        html: txt,
        documentId: 1,
        documentModel: 0,
      }
      this.$http
        .post(`${caseBase}/api-3/parse-html`, data)
        .then((res) => {
          console.log('py接口返回222', res)
          if (res.data) {
            this.onTimeConfirm(new Date())
            this.parsedData = res.data
            this.claimText = this.parsedData.caseInformation[0].suSongQingQiu_1
            this.briefCaseText = this.parsedData.caseInformation[0].shiShiLiYou_1
            this.transformOcrData(res.data)
          } else {
            this.$notify('未识别到起诉书，请重试。（目前仅支持民事起诉状）')
          }
        })
        .catch((err) => {
          Promise.resolve(err)
        })
      const params = {
        paragraph: txt,
      }
      this.$axios.post(`${caseBase}/api-3/parse-sue`, params).then((res) => {
        if (res.data.code === 200) {
          // this.parsedData = res.data
          // this.claimText = this.parsedData.suSongQingQiu_1
          // this.briefCaseText = this.parsedData.shiShiLiYou_1
          this.mechanism = this.parsedData.faYuan
          // this.transformOcrData(res.data)
        }
      })
    },
    // 对当事人数据进行处理 p-代理人数组 s-当事人身份枚举值
    transfromPerson(p, s) {
      console.log('transfromPerson--s')
      const a = []
      let i = 1
      const sObj = subject.find((item) => {
        return item.enum === s
      })
      if (p !== '' && p.length) {
        p.forEach((person) => {
          let _person = {}
          i += 1
          // 如果是法人或非法人类型
          if (person.type === '1' || person.type === '2') {
            const {
              faDingDailiRen: manager, // 法定代理人
              mingCheng, // 公司单位名称
              suoZaiDi, // 原告的住址
              gongZuoDanWei: workOffice, // ⼯工作单位
              lianXiFangShi: phone, // 联系⽅方式
              faDingDaiBiaoRen_zhiWu: position, // 职业
              type, // 原告类型
              zuZhiJiGouDaiMa: idNumber, // 身份证号码
            } = person
            _person = {
              workOffice,
              phone,
              idNumber,
              position,
              manager,
            }
            // 当事人类型
            _person.category = cat[type]
            // 住址
            _person.address = suoZaiDi || null
            _person.name = mingCheng || null
            _person.longResidence = ''
            _person.postalCode = ''
            _person.documentDelivery = ''
          } else {
            const {
              xingMing, // 原告姓名
              mingCheng, // 公司单位名称
              diZhi, // 原告的住址
              gongZuoDanWei: workOffice, // ⼯工作单位
              lianXiFangShi: phone, // 联系⽅方式
              xingBie, // 性别
              minZu: nation, // ⺠民族
              xianZhuZhi, // 现住址
              zhiYe: profession, // 职业
              zhiWu: position, // 职务
              type, // 原告类型
              shenFenZhengHaoMa: idNumber, // 身份证号码
            } = person
            _person = {
              workOffice,
              phone,
              nation,
              idNumber,
              profession,
              position,
            }
            // 当事人类型
            if (xingBie !== '') {
              _person.sex = xingBie === '男' ? 'MALE' : 'FEMALE'
            } else {
              _person.sex = 'UNKNOWN'
            }
            _person.category = cat[type]
            // 住址
            _person.address = diZhi || xianZhuZhi || null
            _person.name = xingMing || mingCheng
            _person.longResidence = ''
            _person.postalCode = ''
            _person.documentDelivery = ''
          }
          // 当事人身份
          _person.procedureSubject = s
          _person.procedureSubjectKeyId = sObj.attrKeyId
          _person.procedureSubjectValueId = sObj.attrValueId
          _person.refname = s + new Date().getTime() + i

          // 2019-05-29 如果当事人是原告则设置为委托人
          _person.displayClient = s === 'PLAINTIFF' ? 'YES' : null
          a.push(_person)
        })
      }

      console.log('transfromPerson--e')
      return a
    },
    // 对文本解析后的数据进行转换
    transformOcrData(data) {
      console.log('transformOcrData--s')
      const anyouParam = this.claimText + this.briefCaseText
      // 解析案由
      this.parseReason(anyouParam)

      // 不添加默认当事人
      this.shouldAddFirstPerson = false

      // 设置审级 默认为 一审 简易程序
      this.setShouldActiveWtrType(false)
      this.shenji = 10014

      const keyVal = {
        yuanGao: 'PLAINTIFF',
        beiGao: 'DEFENDANT',
        anWaiRen: 'NOT_INVOLVED_CASE',
        diSanRen: 'THIRD_PARTY',
        shangSuRen: 'APPELLEE',
        shenQingRen: 'APPLICANT',
        beiShenQingRen: 'CLAIMANT',
      }

      setTimeout(() => {
        this.chengxu = 10020
        this.shouldAddFirstPerson = false
        Object.keys(data).forEach((key) => {
          if (key !== 'caseInformation') {
            if (data[key].length) {
              this.allPerson[keyVal[key]] = this.transfromPerson(data[key], keyVal[key])
            }
          }
        })
        console.log('this.allPerson====>', this.allPerson)
      }, 50)

      console.log('transformOcrData--s')
    },
    // 解析案由
    parseReason(p) {
      console.log('parseReason--s')
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: '正在解析案由',
        forbidClick: true,
      })
      const data = {
        input_keyword: [], // 关键字
        sentence: p, // 文本
        user_id: this.$store.state.user.userInfo.id,
      }
      this.$axios
        .post(`${pydataBase}/api/ai/semantic`, data)
        .then((res) => {
          console.log(1122221, res)
          loading.clear()
          if (res.status === 200 && res.data.result.length) {
            // if (
            //   this.$route.query.useDoc === 'YES' &&
            //   this.$route.query.caseId
            // ) {
            //   this.$refs.reasonItem.clearReason()
            // }
            this.$notify({
              message: '解析案由成功 请选择 ⬇',
              background: '#07c160',
            })
            this.reasonSuggestion = res.data.result
            this.reasonSuggestionFlag = new Date().getTime()
          } else {
            this.$notify({
              message: '未能解析案由 请自行设置',
              background: 'rgba(255, 153, 0, 0.85)',
            })
          }
        })
        .catch((err) => {
          loading.clear()
          Promise.reject(err)
          this.$toast('解析案由失败')
        })
      console.log('parseReason--e')
    },
    // 隐藏案由选择弹层
    hidePopup() {
      this.$refs.reasonItem.hideTreeSelect()
    },
    // 获取案件信息
    async getCaseData(caseId, parseReason = false) {
      console.log('getCaseData--s')
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: '获取案件数据',
        forbidClick: true,
      })
      this.$axios
        .get(`${caseBase}/lts/case/getCase?caseId=${caseId}`)
        .then((res) => {
          loading.clear()
          if (res.status === 200) {
            // console.log(this.doc, parseReason, '解析reason parseReason')
            if (parseReason) {
              this.parseReason(this.doc)
            }
            const { causeName, caseReasonId, litigantList, trialGradeValueId, procedureTypeValueId, acceptCaseTime, chargingMethod, agencyFee } = res.data.data
            // step 1 设置案由
            // console.log(caseReasonId, '设置案由')
            const reason = {
              summary_name: causeName,
              id: caseReasonId,
            }
            this.reasonSuggestion = [reason]
            this.reasonSuggestionFlag = new Date().getTime()
            // console.log(this.reasonSuggestion, '----------> reasonSuggestion')
            // step 1.1 设置法院相关
            this.$axios.get(`${caseBase}/management/legal/case/record/${caseId}/caseId`).then((res) => {
              if (res && res.data && res.data.code === 200 && res.data.data) {
                const { agencyInfs } = res.data.data
                console.log(agencyInfs, ' 有法院数据就获取法院下法官数据')
                if (agencyInfs) {
                  // 从接口中取到数据
                  const { courtName, courtId, judge, caseNo } = agencyInfs
                  this.mechanism = courtName
                  this.courtId = courtId
                  console.log(this.courtId, '  courtId')
                  // 有法院数据就获取法院下法官数据
                  if (this.courtId) {
                    this.selectCourse = true
                    // this.getJudgeList()
                  }
                  if (!judge) {
                    this.FBlist = []
                  } else {
                    if (judge === '[]') {
                      this.FBlist = []
                      return
                    }
                    if (judge.startsWith('[') && judge.endsWith(']')) {
                      this.FBlist = judge.slice(1, judge.length - 1)
                      this.FBlist = this.FBlist.split(',')
                    } else {
                      if (judge.indexOf(',') > -1) {
                        this.FBlist = judge.split(',')
                      } else {
                        this.FBlist = [judge]
                      }
                    }
                  }
                  this.caseNo = caseNo
                } else {
                  // 没取到数据调用其他接口
                  this.$axios
                    .get(`${caseBase}/lts/inf/infOverview?caseId=${caseId}`, {
                      timeout: 5000,
                    })
                    .then((result) => {
                      if (result && result.data && result.data.code === '200' && result.data.data) {
                        const { courtName, courtId, judge, caseNo } = result.data.data.officesInf
                        this.mechanism = courtName
                        this.courtId = courtId
                        // 有法院数据就获取法院下法官数据
                        if (this.courtId) {
                          this.selectCourse = true
                          // this.getJudgeList()
                        }
                        if (!judge) {
                          this.FBlist = []
                        } else {
                          if (judge === '[]') {
                            this.FBlist = []
                            return
                          }
                          if (judge.startsWith('[') && judge.endsWith(']')) {
                            this.FBlist = judge.slice(1, judge.length - 1)
                            this.FBlist = this.FBlist.split(',')
                          } else {
                            if (judge.indexOf(',') > -1) {
                              this.FBlist = judge.split(',')
                            } else {
                              this.FBlist = [judge]
                            }
                          }
                        }
                        this.caseNo = caseNo
                      }
                    })
                }
              }
            })
            // strep 1.2 设置收案时间 收费方式
            if (acceptCaseTime) {
              this.acceptCaseTime = parseInt(acceptCaseTime)
              const time = new Date(this.acceptCaseTime)
              this.acceptCaseDate = this.acceptCaseTime ? new Date(parseInt(this.acceptCaseTime)) : new Date()
              this.acceptTime = time.getFullYear() + '年' + (time.getMonth() + 1) + '月' + time.getDate() + '日'
            }
            if (chargingMethod) {
              this.chargingMethod = chargingMethod
              this.chargingEnum.forEach((item) => {
                if (item.value === chargingMethod) {
                  this.chargingMethodLabel = item.label
                }
              })
            }
            if (agencyFee) {
              this.agencyFee = agencyFee
            }
            // if (agencyFeeReduction) {
            //   this.agencyFeeReduction = agencyFeeReduction
            // }
            // step 2 设置审级
            this.shouldClearChengxu = false
            this.shouldAddFirstPerson = false
            this.shenji = trialGradeValueId || null
            this.chengxu = procedureTypeValueId || null
            if (this.chengxu === this.shenji) {
              this.showChenxu = false
            }
            // console.log(this.shenji, this.chengxu)
            setTimeout(() => {
              this.shouldClearChengxu = true
            }, 100)

            // step 3 设置当事人
            litigantList.forEach((p) => {
              const item = p
              item.refname = genID()
              this.allPerson[p.procedureSubject].push(item)
            })
          }
        })
        .catch((err) => {
          loading.clear()
          Promise.reject(err)
        })
      console.log('getCaseData--e')
    },
    focusHandler(e) {
      const focusElem = document.activeElement
      if (focusElem && (focusElem.type === 'text' || focusElem.type === 'textarea')) {
        // 使用scrollIntoView方法来控制输入框
        const noScroll = focusElem.getAttribute('data-noscroll')
        if (noScroll && noScroll !== 'YES') {
          document.activeElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    resetWtr() {
      this.shouldSetAsWtr = true
      this.setWtrType(undefined)
      this.setShouldActiveWtrType(true)
    },
    // 重新初始化当事人
    refreshPersonDataAndInit() {
      this.activeName = ''
      this.clearPerson()
      this.setShouldActiveWtrType(true)
      this.shouldSetAsWtr = true
      this.addPerson(this.firstPersonType)
      this.shouldAddFirstPerson = false
    },
    prepare() {
      // 如果路由携带案件id则视为编辑案件
      const query = this.$route.query
      if (query) {
        const { caseId, useDoc, useNativeData, briefCaseText, reasonText } = query
        if (caseId && useDoc !== 'YES') {
          this.isEditCaseMode = true
          this.getCaseData(caseId)
        } else if (caseId && useDoc === 'YES') {
          this.isEditCaseMode = true
          this.getCaseData(caseId, true)
        } else if (!caseId && useDoc === 'YES') {
          this.hasDocForCreation = true
          if (useNativeData === 'YES') {
            const iOSUA = 'fb-iOS-Webview'
            const AndroidUA = 'fb-Android-Webview'
            const flutterUA = 'fb-flutter-Webview'

            const ua = navigator.userAgent
            if (ua === AndroidUA) {
              try {
                // eslint-disable-next-line
                const data = AND2JS.getNativeOcrText()
                if (data && data !== '') {
                  this.parseHTML(data)
                } else {
                  this.$notify('当前识别的内容为空 请重试')
                }
              } catch {
                console.log('原生端环境才支持拍照识别新建项目')
              }
            } else if (ua === iOSUA) {
              try {
                // eslint-disable-next-line
                const data = window.webkit.messageHandlers.back.refreshCase({})
                if (data && data !== '') {
                  this.parseHTML(data)
                } else {
                  this.$notify('当前识别的内容为空 请重试')
                }
              } catch {
                console.log('原生端环境才支持拍照识别新建项目')
              }
            } else if (ua === flutterUA) {
              try {
                // eslint-disable-next-line
                const data = window.back.refreshCase('{}')
                if (data && data !== '') {
                  this.parseHTML(data)
                } else {
                  this.$notify('当前识别的内容为空 请重试')
                }
              } catch (err) {
                console.log('back.refreshCase', err)
              }
            }
          } else {
            if (this.doc && this.doc !== '') {
              this.parseHTML(this.doc)
            }
          }
        }
        if (briefCaseText) {
          this.briefCaseText = briefCaseText
        }
        if (reasonText) {
          this.reasonText = reasonText
        }
      }
    },
    rootRecursionName(data, label, indexArray) {
      const arr = Array.from(indexArray)
      for (let i = 0, len = data.length; i < len; i++) {
        arr.push(data[i].label)
        if (data[i].label === label) {
          return arr
        }
        const children = data[i].children
        if (children && children.length) {
          const result = this.rootRecursionName(children, label, arr)
          if (result) return result
        }
        arr.pop()
      }
      return false
    },
    findRootReasonByName(name) {
      const reason = this.rootRecursionName(reasonData, name, [])
      return reason || null
    },
    findFirstCaseReasonById(id, data, indexArray) {
      const arr = Array.from(indexArray)
      for (let i = 0, len = data.length; i < len; i++) {
        const dataid = parseInt(data[i].value)
        arr.push(data[i])
        if (dataid === id) {
          return arr
        }
        const children = data[i].children
        if (children && children.length) {
          const result = this.findFirstCaseReasonById(id, children, arr)
          if (result) return result
        }
        arr.pop()
      }
    },
    removeLabel() {
      this.showJiGouLabel = false
      this.DeleteJiGou()
    },
    // 机构列表点击赋值，并且关闭搜索框
    SelectJiGou(text, id, index) {
      if (this.firstcaseReason.name === '刑事' && this.mechLie.length === 1 && this.mechLie[0].isSure === true) {
        this.sureAdd(text, id)
        return
      }
      this.mechanism = text
      this.showCoursePanel = false
      this.showJiGouLabel = true
      this.courtId = id
      console.log(this.courtId, ' this.courtId  ====>>> SelectJiGou')
      setTimeout(() => {
        this.selectCourse = true
      }, 200)
    },
    // 机构聚焦显示搜索框
    JiGouFocus() {
      this.showCoursePanel = true
      this.BR = true
      this.JiGouSearch(this.mechanism)
    },
    JiGouSearch(val) {
      if (this.BR && val) {
        let url = ''
        if (this.firstcaseReason.name === '刑事') {
          url = `/lts/org?keyword=${val}&type=全部`
          this.$axios.get(`${caseBase}${url}`).then((res) => {
            if (res.data.code === '200') {
              this.mechLie = []
              if (res.data.data.length === 0) {
                // 调用新增法院接口
                this.mechLie.push({
                  label: val,
                  cid: null,
                  isSure: true,
                })
              } else {
                res.data.data.forEach((item) => {
                  this.mechLie.push({
                    label: item.name,
                    cid: item.id,
                  })
                })
              }
            }
          })
        } else {
          url = `/lts/court/queryCourtByName?name=${val}`
          this.$axios.get(`${caseBase}${url}`).then((res) => {
            if (res.data.items) {
              this.mechLie = []
              res.data.items.forEach((item) => {
                this.mechLie.push({
                  label: item.name,
                  cid: item.id,
                })
              })
            }
          })
        }
      } else if (this.BR && !val) {
        this.$axios.get(`${this.$base}/management/user/${this.userId}/info`).then((res) => {
          if (res.data.code === 200) {
            this.mechLie = []
            if (res.data.data.orgs.length > 0) {
              res.data.data.orgs.forEach((item) => {
                this.mechLie.push({
                  label: item.name,
                  cid: item.id,
                  obj: item,
                })
              })
            }
          }
        })
      }
    },
    sureAdd(label, cid) {
      this.mechanism = label
      this.showJiGouLabel = true
      setTimeout(() => {
        this.showCoursePanel = false
      }, 100)
      const arr = ['法院', '检察院', '工商局', '公安局', '仲裁', '看守所', '监狱', '司法局']
      const lastStr3 = label.substr(label.length - 3, 3)
      const lastStr2 = label.substr(label.length - 2, 2)
      let index = arr.indexOf(lastStr2)
      if (index === -1) {
        index = arr.indexOf(lastStr3)
      }
      const orgType = index === -1 ? 8 : index
      if (!cid) {
        this.$axios
          .post(`${this.$base}/lts/org/add`, {
            name: label,
            orgType,
          })
          .then((res) => {
            if (res.data && res.data.code === 200) {
              this.courtId = res.data.data.id
              setTimeout(() => {
                this.selectCourse = true
              }, 200)
            }
          })
      }
    },
    // 机构失焦 搜索框消失
    JiGouBlur() {
      this.BR = false
      if (!this.courtId) {
        this.mechanism = ''
        this.selectCourse = false
      }
      setTimeout(() => {
        this.showCoursePanel = false
      }, 100)
    },
    // 法官列表数据
    getJudgeList() {
      if (this.caseTypeName && this.mechanism) {
        this.$axios
          .post(`${this.$pydataBase}/api/judge`, {
            summary: this.caseTypeName,
            court: this.mechanism,
          })
          .then((res) => {
            this.FGlist = []
            if (res.data && res.data.result.length > 0) {
              this.FGlist = res.data.result.map((item) => item)
            }
          })
      }
    },
    // 清空法院列表
    DeleteJiGou() {
      this.showJiGouLabel = false
      this.mechanism = ''
      this.mechLie = []
      this.courtId = null
      this.showJudgeBlock = false
      this.selectCourse = false
      this.judges = []
      this.judge = ''
      this.FGlist = []
      this.caseNo = ''
      // if (!this.editCaseMode) {
      //   // 新增模式下清空法院重新选择，编辑模式下不可修改
      //   this.shenji = ''
      // }
    },
    // 通过案由+法院搜索法官的点击事件
    FGLIst(val) {
      this.showJudgePanel = false
      if (this.FBlist.length === 3) {
        this.$notify('最多能添加三个法官')
        return false
      }
      if (this.FBlist.indexOf(val.trim()) > -1) {
        this.$notify('已添加该法官')
        return false
      } else {
        if (val.trim()) {
          this.FBlist.push(val.trim())
        }
      }
      this.faguan = ''
      this.FGlist = []
    },
    // 空格键处理
    hanldeKeySpace(e) {
      // console.log('空格键处理', this.faguan)
      if (this.faguan.trim() !== '' && this.faguan.length && this.faguan.indexOf(' ') > -1) {
        this.FGLIst(this.faguan)
        this.faguan = ''
        this.FGlist = []
      }
    },
    // 键盘按下生成法官事件 添加法官
    // addJudge(e) {
    //   const enter = e.keyCode === 13 || e.keyCode === 32
    //   console.log(e.keyCode, e, 'e====>')
    //   if (enter && this.faguan.trim().length > 0) {
    //     this.FGLIst(this.faguan)
    //     this.faguan = ''
    //     this.FGlist = []
    //   }
    // },
    // 法官列表聚焦的时候显示搜索框
    FB() {
      let getDataSuc = false
      this.showJudgePanel = true
      this.$axios
        .post(`${pydataBase}/api/judge`, {
          summary: this.caseTypeName,
          court: this.mechanism,
        })
        .then((res) => {
          getDataSuc = true
          this.emptyFG = false
          this.FGlist = []
          if (res.data && res.data.result.length > 0) {
            res.data.result.forEach((item) => {
              if (this.FBlist.indexOf(item) > -1) {
                return
              }
              this.FGlist.push(item.name)
              this.enclose = Object.assign(this.FGlist)
            })
          } else if (res.data && res.data.result.length === 0) {
            this.emptyFG = true
          }
        })
        .catch(() => {
          getDataSuc = true
          this.emptyFG = true
          this.FGlist = []
          // this.showJudgePanel = false
        })
      // 请求时长太长了,尚未返回数据先设定为空数据
      setTimeout(() => {
        if (!getDataSuc) {
          this.FGlist = []
          this.emptyFG = true
        }
      }, 1500)
    },
    // 法官失焦 搜索框关闭
    ShiJiao() {
      setTimeout(() => {
        this.showJudgePanel = false
      }, 100)
      if (this.faguan.trim()) {
        this.FGLIst(this.faguan)
      }
    },
    // 删除法官
    deleteJudge(num) {
      this.FBlist.splice(num, 1)
    },
  },
  async created() {
    const firstCaseReason = this.findFirstCaseReasonById(10123, reasonData, [])[0]
    const firstCaseReasonId = parseInt(firstCaseReason.value)
    this.firstcaseReason = caseAddRadioData.caseTypesV2.find((item) => {
      return item.id === firstCaseReasonId
    })
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/createCaseOcr
    this.$hook(this.$bridge.createCaseOcr, this.ocrCaseResultCallback)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.createCaseOcr)
    })
    window.ocrCaseResult = this.ocrCaseResultCallback
    // setTimeout(() => {
    //   this.prepare()
    // }, 500)
    this.prepare()
    await this.getId()
  },
  mounted() {
    // 设置监听聚焦事件
    document.body.addEventListener('focus', this.focusHandler, true)
    if (this.$route.query.isBackCaseManager) {
      this.isBackCaseManager = true
    } else {
      this.isBackCaseManager = false
    }
    console.log(this.isBackCaseManager)
  },
  beforeDestroy() {
    this.setReason(null)
    this.resetWtr()
  },
  destroyed() {
    document.body.removeEventListener('focus', this.focusHandler)
  },
  metaInfo() {
    return {
      title: '新建项目',
    }
  },
  watch: {
    chengxu(nv, ov) {
      /* #简易程序/普通程序/小额诉讼# 与 #特别程序# 之间的切换 当事人类型会发生变化 需要清空当事人数据
       * * 通过判断chengxu的新值与旧值来实现这个功能
       */
      if (nv !== ov && ov !== 0 && nv !== '' && nv !== 0) {
        if (this.shenji === 10014) {
          if ((nv === 10020 || nv === 10021 || nv === 12063) && ov !== 10022) {
            // 当事人类型相同保留当事人数据
            console.log('保留当事人数据')
          } else {
            console.log('清空当事人数据')
            this.refreshPersonDataAndInit()
          }
        }
      }
    },
    reason(nv, ov) {
      // this.showJiGouLabel = false
      if (nv) {
        const { text, id } = nv
        if (text) {
          const r = this.findRootReasonByName(text)
          if (r) {
            this.caseTypeName = r[0]
          }
        }
        const firstCaseReason = this.findFirstCaseReasonById(id, reasonData, [])[0]
        const firstCaseReasonId = parseInt(firstCaseReason.value)
        this.firstcaseReason = caseAddRadioData.caseTypesV2.find((item) => {
          return item.id === firstCaseReasonId
        })
        if (this.firstcaseReason) {
          this.shenjiCell = this.firstcaseReason.sub
        } else {
          this.shenjiCell = []
        }
      } else {
        // 清空案由数据
        this.initData()
        this.caseTypeName = ''
      }
    },
    firstcaseReason(val, old) {
      console.log('顶级案由切换', val, old)
      if ((val && val.id === 10968) || (old && old.id === 10968)) {
        this.DeleteJiGou()
      }
      if (!this.isEditCaseMode) {
        this.resetInitData()
        this.getUsers()
      } else {
        if (old) {
          console.log('编辑模式  顶级案由切换', val, old)
          this.dealEditReset()
        }
      }
    },
    // 机构搜索
    mechanism(val) {
      this.JiGouSearch(val)
      /* if (this.BR && val) {
        let url = ''
        if (this.firstcaseReason.id === 10968) {
          url = `/lts/org?keyword=${val}&type=全部`
        } else {
          url = `/lts/court/queryCourtByName?name=${val}`
        }
        console.log(url, 'url url')
        this.$axios.get(`${caseBase}${url}`).then(res => {
          console.log(res.data, '###########')
          if (res.data.items) {
            this.mechLie = []
            res.data.items.forEach(item => {
              this.mechLie.push({
                label: item.name,
                cid: item.id
              })
            })
          }
        })
      } else if (this.BR && !val) {
        this.$axios
          .get(`${this.$base}/management/user/${this.userId}/info`)
          .then(res => {
            if (res.data.code === 200) {
              this.mechLie = []
              if (res.data.data.orgs.length > 0) {
                res.data.data.orgs.forEach(item => {
                  this.mechLie.push({
                    label: item.name,
                    cid: item.id,
                    obj: item
                  })
                })
              }
            }
          })
      } */
    },
    // 法官列表
    faguan(val) {
      if (this.enclose) {
        this.FGlist = Object.assign(this.enclose)
      }
      if (val.trim().length === 0) {
        return false
      }
      this.FGlist = this.FGlist.filter(function (item) {
        return item.indexOf(val.trim()) > -1
      })
    },
  },
}
</script>

<style lang="stylus">
.use-ocr
  width 28px
  i
    font-size 22px
    color #569AF9
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.create-case-wrapper
  overflow-y auto
  position relative
  transition all 0.8s ease
  & .oa-approval
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
.create-case-form
  background #FFFFFF
  padding-top 10px
  height 100%
  .basic-section
    background #fff
    margin 0px 10px 5px 10px
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    border-radius 4px
    .course
      width 92%
      height 51px
      line-height 51px
      display flex
      color inherit
      font inherit
      justify-content space-between
      margin 0px auto
      i
        height 51px
        line-height 51px
      .liebiao
        width 100%
        position absolute
        top 45px
        left 0px
        background #ffffff
        box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
        border-radius 5px
        z-index 9999
        padding-bottom 30px
        overflow auto
        height 200px
        .load
          position absolute
          width 100%
        li
          width 100%
          height 40px
          line-height 40px
          font-size 0.373333rem
          color #666666
          text-indent 15px
          margin-top 5px
          border-bottom 1px solid #eee
          display flex
          justify-content space-between
          span:nth-child(2)
            color blue
            margin-right 10px
    .judge
      border-bottom 1px solid #F3F4F7
      .DiZao
        height 35px
        padding-left 5px
        padding-right 5px
        overflow hidden
        line-height 35px
        align-self center
        text-align left
        text-indent 5px
        min-width 80px
        background #f7f7f7
        margin-right 10px
        position relative
        i
          display inline-block
          position absolute
          top 0px
          right 5px
        span
          display inline-block
          width 70%
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
    .JiGou
      width 92%
      height 51px
      line-height 51px
      display flex
      justify-content space-between
      margin 0px auto
      .judge-input-wrapper
        input
          line-height 20px !important
          text-align right
      .course-input-wrapper
        input
          line-height 20px !important
      div:nth-child(1)
        font-size 0.373333rem
        width 60px
      div:nth-child(2)
        width 100%
        height 100%
        position relative
        display flex
        .judge-caseno
          font-size 0.373333rem
          color #969799
          font-weight lighter
        input
          height 90%
          font-size 14px
          flex-grow 1
          align-self center
          border none
          width 50%
        .jigou-label
          position absolute
          font-size 0.373333rem
          top 0px
          left 0px
          background #eee
          border-radius 3px
          z-index 9
          overflow auto
          line-height 40px
          height 40px
          margin 5px 0
          padding 0 10px
          width 70%
          display flex
          // justify-content space-between
          box-sizing border-box
          & .iconfont
            height 40px !important
            line-height 40px !important
        .liebiao
          width 100%
          position absolute
          top 56px
          left 0px
          background #ffffff
          box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
          border-radius 5px
          z-index 9999
          padding-bottom 30px
          overflow auto
          max-height 200px
          .load
            position absolute
            width 100%
          li
            width 100%
            height 40px
            line-height 40px
            font-size 14px
            color #666666
            text-indent 14px
            margin-top 5px
            border-bottom 1px solid #eee
            &.no-data
              color #333
              text-align center
    .reason-wrapper
      display flex
      align-items center
      .reason-item
        flex 1
  .add-other-type-person
    padding 10px 20px
    font-size 14px
    text-align right
    color #569AF9
    i
      font-size 14px
  .yuangao-section, .beigao-section, .basic-section, .person-section, .time-section
    margin 5px 10px
    background #ffffff
    border-radius 4px
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    .sec
      padding 10px
      display flex
      justify-content space-between
      align-items center
      .title
        font-size 14px
        padding-left 5px
        color #333
      .action
        text-decoration none
        color #569AF9
        font-size 14px
        i
          font-size 14px
    .shenlijibie
      border-radius 0 0 4px 4px
      .van-collapse-item
        border-radius 0 0 4px 4px
        .van-cell
          border-radius 0 0 4px 4px
          .van-cell__value
            color #666
          .van-cell__title
            span:before
              content '*'
              color red
      .van-collapse-item__wrapper
        border-radius 0 0 4px 4px
        background #F0F6FD
        .van-collapse-item__content
          color #444
          .shenji-radio-group, .chengxu-radio-group
            display flex
            padding 5px 0
            justify-content space-between
            align-items center
            .van-radio
              display flex
              justify-content center
              align-items center
              .van-radio__input
                display block
                padding-left 1px
                box-sizing border-box
                font-size 18px
                height 18px
              .van-radio__label
                display block
                margin-left 3px
                margin-top 2.4px
                font-size 14px
            .van-radio .van-icon-checked
              color #2781FD
          .shenji-radio-group
            border-bottom 0.026667rem solid #ebebeb
            margin-bottom 10px
            padding-bottom 10px
  .create-case-btn
    display flex
    justify-content center
    .btn
      border-radius 2px
      background #569AF9
      color #fefefe
      padding 4px 20px
      font-size 16px
      margin 20px 0
.title-with-switch
  display flex
  align-items center
  justify-content space-between
  .input
    flex 1
    margin-left 10px
    input
      text-align right
.person-info-cell-field.van-cell.van-field, .text-right
  .van-cell__value
    .van-field__body
      .van-field__control
        text-align right
.person-list
  border-radius 0 0 4px 4px
  .person-item
    .person-swiper-cell.van-swipe-cell
      z-index 2
      .van-swipe-cell__wrapper
        .van-swipe-cell__right
          color #fff
          font-size 15px
          width 65px
          height 44px
          display inline-block
          text-align center
          line-height 44px
          background-color #f44
          box-sizing border-box
          border none
  .person-item:nth-last-child(1)
    margin-bottom 8px
    padding-bottom 8px
    border-radius 0 0 4px 4px
.person-info-cell.van-collapse
  margin-top -42px
  z-index 1
.form-wrapper
  padding 0.266667rem 0
  margin 0 0 0 0.4rem
  position relative
  box-sizing border-box
  width clac(100% - 10px)
  &::after
    content ''
    position absolute
    pointer-events none
    box-sizing border-box
    left 0rem
    bottom 0
    width 100%
    transform scaleY(0.5)
    border-bottom 0.026667rem solid #d2d2d2
  .form-item
    display flex
    align-items center
    .label
      font-size 14px
      color #333
      justify-content space-between
    .van-radio-group
      display flex
      flex 1
      justify-content flex-end
      align-items center
      .van-radio
        margin 0 10px
        .van-radio__input
          font-size 13px
          line-height 14px
          padding-left 2px
          box-sizing border-box
      .van-radio .van-icon-checked
        color #2781FD
.time-section
  .shou-fei
    width 100px
    text-align right
    padding-right 16px
    box-sizing border-box
    height 48px
    line-height 48px
    font-size 0.373333rem
    color #969799
    font-weight lighter
  .accept-time.van-cell
    .van-cell__title.van-field__label span:before
      content '*'
      color red
.yuangao-section .sec:nth-child(1) .title:before
  content '*'
  color red
</style>
