var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    { attrs: { bgColor: { background: "#eef7fc" } } },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c(
            "div",
            {
              staticClass: "use-ocr",
              attrs: { slot: "right" },
              on: { click: _vm.openCaseCamera },
              slot: "right",
            },
            [_c("i", { staticClass: "iconfont icon-camera" })]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "wrapper",
          staticClass: "create-case-wrapper",
          style: _vm.createCaseWrapperStyle,
        },
        [
          _c(
            "div",
            {
              staticClass: "create-case-form",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.hidePopup.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "basic-section" },
                [
                  _c(
                    "div",
                    { staticClass: "reason-wrapper" },
                    [
                      _c("reason-item", {
                        ref: "reasonItem",
                        attrs: {
                          reasonSuggestion: _vm.reasonSuggestion,
                          reasonText: _vm.reasonText,
                          flag: _vm.reasonSuggestionFlag,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCourseBlock,
                          expression: "showCourseBlock",
                        },
                      ],
                      staticClass: "course JiGou",
                    },
                    [
                      _c("div", [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mechanism,
                              expression: "mechanism",
                            },
                          ],
                          staticClass: "iconfont icon-clear-2 mjechanism-clear",
                          staticStyle: {
                            "margin-right": "10px",
                            color: "#c8c9cc",
                          },
                          on: { click: _vm.DeleteJiGou },
                        }),
                      ]),
                      _c("div", { staticClass: "course-input-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mechanism,
                              expression: "mechanism",
                            },
                          ],
                          ref: "inputRef",
                          staticStyle: { "line-height": "20px" },
                          attrs: {
                            type: "text",
                            placeholder: "请填写审理机关",
                          },
                          domProps: { value: _vm.mechanism },
                          on: {
                            focus: _vm.JiGouFocus,
                            blur: _vm.JiGouBlur,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.mechanism = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "judge-caseno",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.showJudgeBlock = !_vm.showJudgeBlock
                              },
                            },
                          },
                          [_vm._v("审理人员案号")]
                        ),
                        _vm.showJiGouLabel
                          ? _c(
                              "div",
                              {
                                staticClass: "jigou-label",
                                on: {
                                  click: function ($event) {
                                    _vm.showJiGouLabel = false
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.mechanism))])]
                            )
                          : _vm._e(),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCoursePanel,
                                expression: "showCoursePanel",
                              },
                            ],
                            staticClass: "liebiao",
                            on: {
                              click: function ($event) {
                                _vm.showCoursePanel = false
                              },
                            },
                          },
                          _vm._l(_vm.mechLie, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.SelectJiGou(
                                      item.label,
                                      item.cid,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.label))]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.isSure,
                                        expression: "item.isSure",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.sureAdd(item.label, item.cid)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCourseBlock && _vm.showJudgeBlock,
                          expression: "showCourseBlock && showJudgeBlock",
                        },
                      ],
                      staticClass: "judge JiGou",
                    },
                    [
                      _c("div", [_c("span", [_vm._v("办案人")])]),
                      _c(
                        "div",
                        { staticClass: "judge-input-wrapper" },
                        [
                          _vm._l(_vm.FBlist, function (item, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "DiZao" },
                              [
                                _c("span", [_vm._v(_vm._s(item))]),
                                _c("i", {
                                  staticClass: "iconfont icon-clear",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteJudge(index)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.faguan,
                                expression: "faguan",
                              },
                            ],
                            staticStyle: { "line-height": "20px" },
                            attrs: {
                              type: "text",
                              placeholder: "请填写或选择",
                            },
                            domProps: { value: _vm.faguan },
                            on: {
                              focus: _vm.FB,
                              keyup: _vm.hanldeKeySpace,
                              blur: _vm.ShiJiao,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.faguan = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showJudgePanel,
                                  expression: "showJudgePanel",
                                },
                              ],
                              staticClass: "liebiao",
                            },
                            [
                              _vm._l(_vm.FGlist, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        return _vm.FGLIst(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item) + " ")]
                                )
                              }),
                              _vm.emptyFG
                                ? _c("li", { staticClass: "no-data" }, [
                                    _vm._v("无推荐数据"),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCourseBlock && _vm.showJudgeBlock,
                          expression: "showCourseBlock && showJudgeBlock",
                        },
                      ],
                      staticClass: "caseNo",
                    },
                    [
                      _c("van-field", {
                        attrs: {
                          label: "案号",
                          placeholder: "请输入并选择案号",
                          "input-align": "right",
                          clearable: "",
                        },
                        model: {
                          value: _vm.caseNo,
                          callback: function ($$v) {
                            _vm.caseNo = $$v
                          },
                          expression: "caseNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "van-collapse",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.showCourseBlock &&
                              _vm.selectCourse &&
                              _vm.mechanism) ||
                            (_vm.shenji && _vm.chengxu && _vm.reason),
                          expression:
                            "(showCourseBlock && selectCourse && mechanism) || (shenji && chengxu && reason)",
                        },
                      ],
                      staticClass: "shenlijibie",
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "van-collapse-item",
                        {
                          attrs: {
                            title: "审级",
                            name: "shenjicollapse",
                            value: _vm.shenlijibie,
                          },
                        },
                        [
                          _c(
                            "van-radio-group",
                            {
                              staticClass: "shenji-radio-group",
                              on: { change: _vm.shenjiChange },
                              model: {
                                value: _vm.shenji,
                                callback: function ($$v) {
                                  _vm.shenji = $$v
                                },
                                expression: "shenji",
                              },
                            },
                            _vm._l(_vm.shenjiCell, function (s) {
                              return _c(
                                "van-radio",
                                { key: s.id, attrs: { name: s.attrValueId } },
                                [_vm._v(_vm._s(s.name))]
                              )
                            }),
                            1
                          ),
                          _c(
                            "van-radio-group",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.shenji && _vm.showChenxu,
                                  expression: "shenji && showChenxu",
                                },
                              ],
                              staticClass: "chengxu-radio-group",
                              on: { change: _vm.chengxuChange },
                              model: {
                                value: _vm.chengxu,
                                callback: function ($$v) {
                                  _vm.chengxu = $$v
                                },
                                expression: "chengxu",
                              },
                            },
                            _vm._l(_vm.chengxuCell, function (s) {
                              return _c(
                                "van-radio",
                                { key: s.id, attrs: { name: s.attrValueId } },
                                [_vm._v(_vm._s(s.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              (_vm.showCourseBlock && _vm.chengxu) ||
              (!_vm.showCourseBlock && _vm.reason)
                ? _c("div", { staticClass: "time-section" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("van-field", {
                          staticClass: "accept-time",
                          attrs: {
                            readonly: "",
                            clickable: true,
                            clearable: true,
                            name: "datetimePicker",
                            value: _vm.acceptTime,
                            label: "收案时间",
                            placeholder: "点击选择时间",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showTimePicker = true
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "shou-fei",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.showSF = !_vm.showSF
                              },
                            },
                          },
                          [_vm._v("收费")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSF,
                            expression: "showSF",
                          },
                        ],
                      },
                      [
                        _c("van-field", {
                          attrs: {
                            readonly: "",
                            clickable: true,
                            clearable: true,
                            name: "picker",
                            value: _vm.chargingMethodLabel,
                            label: "收费方式",
                            placeholder: "点击选择收费方式",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showMethodPicker = true
                            },
                          },
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: { position: "bottom" },
                            model: {
                              value: _vm.showMethodPicker,
                              callback: function ($$v) {
                                _vm.showMethodPicker = $$v
                              },
                              expression: "showMethodPicker",
                            },
                          },
                          [
                            _c("van-picker", {
                              attrs: {
                                "show-toolbar": "",
                                columns: _vm.chargingEnumOptions,
                              },
                              on: {
                                confirm: _vm.onMethodConfirm,
                                cancel: function ($event) {
                                  _vm.showMethodPicker = false
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("van-field", {
                          attrs: {
                            label: "律师费",
                            name: "agencyFee",
                            type: "number",
                            clickable: true,
                            clearable: true,
                            "input-align": "right",
                            placeholder: "请输入律师费",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button",
                                fn: function () {
                                  return [_c("span", [_vm._v("元")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1347566867
                          ),
                          model: {
                            value: _vm.agencyFee,
                            callback: function ($$v) {
                              _vm.agencyFee = $$v
                            },
                            expression: "agencyFee",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPersonBlock && !_vm.isEmptyCharging,
                      expression: "showPersonBlock && !isEmptyCharging",
                    },
                  ],
                  staticClass: "person-area",
                },
                [
                  _c("div", { staticClass: "yuangao-section" }, [
                    _c("div", { staticClass: "sec" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.firstPersonName)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "action",
                          on: {
                            click: function ($event) {
                              return _vm.addPerson(_vm.firstPersonType)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-tianjia1" }),
                          _vm._v(_vm._s("添加" + _vm.firstPersonName)),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "person-list" },
                      _vm._l(
                        _vm.filterAllPerson(_vm.firstPersonType),
                        function (p, i) {
                          return _c("person-item", {
                            key: p.refname,
                            ref: p.refname,
                            refInFor: true,
                            attrs: {
                              personData: p,
                              index: i,
                              type: _vm.firstPersonGroup,
                            },
                            on: {
                              resetDefaultWtr: _vm.resetWtr,
                              deletePerson: _vm.handleDeletePerson,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ]),
                  _vm.personGroup.length >= 2
                    ? _c("div", { staticClass: "beigao-section" }, [
                        _c("div", { staticClass: "sec" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.secondPersonName)),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "action",
                              on: {
                                click: function ($event) {
                                  return _vm.addPerson(_vm.secondPersonType)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-tianjia1",
                              }),
                              _vm._v(_vm._s("添加" + _vm.secondPersonName)),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "person-list" },
                          _vm._l(
                            _vm.filterAllPerson(_vm.secondPersonType),
                            function (p, i) {
                              return _c("person-item", {
                                key: p.refname,
                                ref: p.refname,
                                refInFor: true,
                                attrs: {
                                  personData: p,
                                  index: i,
                                  type: _vm.secondPersonGroup,
                                },
                                on: {
                                  resetDefaultWtr: _vm.resetWtr,
                                  deletePerson: _vm.handleDeletePerson,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.otherGroup.length
                    ? _c(
                        "div",
                        { staticClass: "other-sections" },
                        _vm._l(_vm.otherGroup, function (g) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.filterAllPerson(g.enum).length,
                                  expression: "filterAllPerson(g.enum).length",
                                },
                              ],
                              key: g.attrValueId,
                              staticClass: "person-section",
                            },
                            [
                              _c("div", { staticClass: "sec" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(g.name)),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "action",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPerson(g.enum)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-tianjia1",
                                    }),
                                    _vm._v(" " + _vm._s("添加" + g.name)),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "person-list" },
                                _vm._l(
                                  _vm.filterAllPerson(g.enum),
                                  function (p, i) {
                                    return _c("person-item", {
                                      key: p.refname,
                                      ref: p.refname,
                                      refInFor: true,
                                      attrs: {
                                        personData: p,
                                        index: i,
                                        type: g,
                                      },
                                      on: {
                                        resetDefaultWtr: _vm.resetWtr,
                                        deletePerson: _vm.handleDeletePerson,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.otherGroup.length && _vm.chengxu
                    ? _c(
                        "div",
                        { staticClass: "add-other-type-person" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showOtherGroupPicker = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-tianjia1",
                              }),
                              _vm._v(" 添加其他当事人 "),
                            ]
                          ),
                          _c(
                            "van-action-sheet",
                            {
                              model: {
                                value: _vm.showOtherGroupPicker,
                                callback: function ($$v) {
                                  _vm.showOtherGroupPicker = $$v
                                },
                                expression: "showOtherGroupPicker",
                              },
                            },
                            [
                              _c("van-picker", {
                                attrs: {
                                  columns: _vm.otherGroupPickerData,
                                  "item-height": _vm.pickCellheight,
                                  "show-toolbar": "",
                                  title: "选择当事人类型",
                                  "visible-item-count": 5,
                                },
                                on: {
                                  cancel: function ($event) {
                                    _vm.showOtherGroupPicker = false
                                  },
                                  confirm: _vm.onOtherGroupPickerConfirm,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              this.organizationId &&
              this.hasApprovalUser &&
              this.isBackCaseManager &&
              !this.isEditCaseMode &&
              !this.createCaseBtnStyle.background
                ? _c("approval-user", {
                    attrs: {
                      Approval: this.Approval,
                      CC: this.CC,
                      isAdd: 0,
                      isShowCaseMode: true,
                      caseMode: _vm.caseMode,
                      approvalUserSource: _vm.approvalUser,
                      ccUserSource: _vm.ccUser,
                    },
                    on: {
                      userSelect: _vm.userSelect,
                      coopSelect: _vm.coopSelect,
                      changeCaseMode: _vm.changeCaseMode,
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "create-case-btn" }, [
                !_vm.isBackCaseManager
                  ? _c(
                      "div",
                      {
                        staticClass: "btn",
                        style: _vm.createCaseBtnStyle,
                        on: {
                          click: function ($event) {
                            return _vm.createCase("YES")
                          },
                        },
                      },
                      [_vm._v("保存案件")]
                    )
                  : _vm._e(),
                _vm.isBackCaseManager && this.organizationId
                  ? _c(
                      "div",
                      {
                        staticClass: "btn",
                        staticStyle: { "margin-left": "10px" },
                        style: _vm.createCaseBtnStyle,
                        on: {
                          click: function ($event) {
                            return _vm.createCase("YES", "UNAUDITED")
                          },
                        },
                      },
                      [_vm._v("申请立案")]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("van-dialog", {
            attrs: {
              "show-cancel-button": "",
              title: "疑似存在冲突案件",
              message: "系统检测发现，疑似存在冲突案件，是否继续创建？",
            },
            on: { confirm: _vm.confirmCreateConflictCase },
            model: {
              value: _vm.showConflict,
              callback: function ($$v) {
                _vm.showConflict = $$v
              },
              expression: "showConflict",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "get-container": "body" },
          model: {
            value: _vm.showTimePicker,
            callback: function ($$v) {
              _vm.showTimePicker = $$v
            },
            expression: "showTimePicker",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.onTimeConfirm,
              cancel: function ($event) {
                _vm.showTimePicker = false
              },
            },
            model: {
              value: _vm.acceptCaseDate,
              callback: function ($$v) {
                _vm.acceptCaseDate = $$v
              },
              expression: "acceptCaseDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }